import React, {
  Fragment, useState,
} from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Tab, TextField } from '@material-ui/core';
import Spinner from '../../components/CustomSpinner';
import Tabs from '../../components/CustomTabs';
import useCommunityDetails from './useCommunityDetails.hook';
import CommunitiesInfoCard from './CommunitiesInfoCard';


import useStyles from './styles';

type Props = {
} & RouteComponentProps & {match:{params: {id: string}}};

const CommunitiesDetailsPage: React.FC<Props> = ({ history, match, location }) => {
  const classes = useStyles();
  const { loading, community, setCommunity } = useCommunityDetails(match.params.id);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const digitCodes = ['nternow_hub_backup_code', 'community_backup_code', 'sales_code', 'trade_code'];

  const handleChange = (event: React.ChangeEvent<{}>, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };

  const handleCommunityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (digitCodes.includes(name)) {
      setCommunity({ ...community, [name]: value.replace(/\D/g,'') });
    } else {
      setCommunity({ ...community, [name]: value });
    }
    
  };

  return (
    <Fragment>
      {loading && (
        <div className={classes.spinnerWrapper}>
          <Spinner />
        </div>
      )}
      <div className={classes.actionsBar}>
        <Paper elevation={3}>
          <Button>
            <ArrowLeftIcon />
          </Button>
          <Button>
            <ArrowRightIcon />
          </Button>
        </Paper>
        <div className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            className={classes.backToButton}
            component={Link}
            to="/communities"
          >
            <ArrowLeftIcon />
            Communities
          </Button>
          <Button variant="contained" className={classes.actionButton}>
            Actions
          </Button>
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <Grid container spacing={4}>
          <>
            <Grid item xs={12} md={4}>
              <CommunitiesInfoCard community={community} setCommunity={setCommunity} communityId={match.params.id} />
            </Grid>
            <Grid item xs={12} md={8}>
              <div className={classes.rightSide}>
                <Tabs value={tabIndex} onChange={handleChange}>
                  <Tab label="Integrations" />
                  <Tab label="Automations" />
                </Tabs>
                <div className={classes.tabsContent}>
                  {tabIndex === 0 && (
                    <Grid container spacing={4}>
                      <Grid item xs>
                        <TextField
                          fullWidth
                          margin="normal"
                          id="lasso_community_project_id"
                          name="lasso_community_project_id"
                          label="Lasso Community Project ID"
                          value={community?.lasso_community_project_id ?? ''}
                          onChange={handleCommunityChange}
                        />
                      </Grid>
                      <Grid item xs>
                        <TextField
                          fullWidth
                          margin="normal"
                          id="novihome_integration"
                          name="novihome_integration"
                          label="NoviHome Integration"
                          value={community?.novihome_integration ?? ''}
                          onChange={handleCommunityChange}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {tabIndex === 1 && (
                    <Grid container spacing={4}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          margin="normal"
                          id="nternow_hub_backup_code"
                          name="nternow_hub_backup_code"
                          label="NterNow Hub Backup Code"
                          value={community?.nternow_hub_backup_code ?? ''}
                          onChange={handleCommunityChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          margin="normal"
                          id="community_backup_code"
                          name="community_backup_code"
                          label="Community Master Code"
                          value={community?.community_backup_code ?? ''}
                          onChange={handleCommunityChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          margin="normal"
                          id="trade_code"
                          name="trade_code"
                          label="Trade Code"
                          value={community?.trade_code ?? ''}
                          onChange={handleCommunityChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          margin="normal"
                          id="sales_code"
                          name="sales_code"
                          label="Sales Code"
                          value={community?.sales_code ?? ''}
                          onChange={handleCommunityChange}
                        />
                      </Grid>
                    </Grid>
                  )}
                </div>
              </div>
            </Grid>
          </>
        </Grid>
      </div>
    </Fragment>
  );
};

export default React.memo(CommunitiesDetailsPage);
